.notfound {
  margin: 0 auto;
  max-width: 850px;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.group > svg {
  width: 100%;
}
.group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
#zero {
  animation: zero 15s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}
#perso {
  animation: perso 15s ease infinite;
  animation-delay: 1s;
  transform-origin: bottom left;
  transform-box: fill-box;
}
#perso3 {
  animation: perso2 15s ease infinite;
  animation-delay: 1s;
  transform-origin: center;
  transform-box: fill-box;
}
#part1 {
  animation: part1 15s ease infinite;
  animation-delay: 1s;
  transform-origin: center;
  transform-box: fill-box;
}
#part2 {
  animation: part1 15s ease infinite;
  animation-delay: 1s;
  transform-origin: center;
  transform-box: fill-box;
}
#part3 {
  animation: part1 15s ease infinite;
  animation-delay: 1s;
  transform-origin: center;
  transform-box: fill-box;
}
#perso2 {
  animation: perso3 15s ease infinite;
  animation-delay: 1s;
  transform-origin: center;
  transform-box: fill-box;
}
#notFound {
  animation: not 1s ease infinite;
  animation-delay: 1s;
  transform-origin: center;
  transform-box: fill-box;
}
@keyframes not {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes perso3 {
  0% {
    transform: translateX(400%) translateY(10%) rotate(-15deg);
  }

  80% {
    transform: translateX(300%) translateY(10%) rotate(-25deg);
  }
  100% {
    transform: translateX(400%) translateY(10%) rotate(-15deg);
  }
}

@keyframes part1 {
  0% {
    transform: translateX(-100%) translateY(-900%);
  }
  80% {
    transform: translateX(-100%) translateY(900%);
  }
  100% {
    transform: translateX(-100%) translateY(-900%);
  }
}

@keyframes perso2 {
  0% {
    transform: translateX(25%) rotate(0);
  }
  80% {
    transform: translateX(-10%) rotate(-12deg);
  }
  100% {
    transform: translateX(25%) rotate(0);
  }
}
@keyframes zero {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}
@keyframes perso {
  0% {
    transform: translateX(25%) rotate(0) translateY(0);
  }
  80% {
    transform: rotate(-90deg) translateX(-20%);
  }
  100% {
    transform: translateX(25%) rotate(0) translateY(0);
  }
}
