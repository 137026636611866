#box1A {
  animation: box1 4s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}
#box2A {
  animation: box2 4s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}
#box3A {
  animation: box3 4s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}
#box4 {
  animation: box4 4s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}
@keyframes box1 {
  0% {
    transform: translate(0, 0) scale(0.5);
  }
  50% {
    transform: translate(-30%, 20%) scale(1);
  }
  100% {
    transform: translate(0, 0) scale(0.5);
  }
}
@keyframes box2 {
  0% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(-10%, 30%) scale(1.2);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}
@keyframes box3 {
  0% {
    transform: translate(0, 20%) scale(1);
  }
  50% {
    transform: translate(40%, 50%) scale(0.5);
  }
  100% {
    transform: translate(0, 20%) scale(1);
  }
}
@keyframes box4 {
  0% {
    transform: translate(0, 0) scale(1.4);
  }
  50% {
    transform: translate(40%, 20%) scale(0.5);
  }
  100% {
    transform: translate(0, 0) scale(1.4);
  }
}
