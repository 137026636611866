.commingSoon {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.comingButton {
  position: fixed;
  top: 20px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  animation: buttonShow 0.5s ease forwards;
  animation-delay: 8s;
}
.comingButton > a {
  text-decoration: none;
  color: #f50057;
  font-size: 1.3em;
  font-weight: 700;
  border: 2px solid #f50057;
  padding: 2px 5px;
}
.comingButton:hover {
  transform: scale(1.1);
}

.comingButton:hover a{
  background-color: #f50057;
  color: aliceblue;
}

@keyframes buttonShow {
  to{
    opacity: 1;
  }
}

.logoSvg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoSvg #raizelText path:nth-child(1) {
  stroke-dasharray: 770;
  stroke-dashoffset: 770;
  animation: draw 5s ease-in-out forwards,
            fillText 1s ease-in-out forwards 5s;
}

.logoSvg #raizelText path:nth-child(2) {
  stroke-dasharray: 770;
  stroke-dashoffset: 770;
  animation: draw 2s ease-in-out forwards 4.5s,
  fillPoint 1s ease-in-out forwards 5s;
}

.soon #coming path {
  stroke-dasharray: 770;
  stroke-dashoffset: 770;
  animation: draw 5s ease-in-out forwards 6s
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fillText {
  from{
    fill: transparent;
  }

  to {
    fill: black;
  }
}

@keyframes fillPoint {
  from{
    fill: transparent;
  }

  to {
    fill: #f50057;
  }
}

.flowerSvg #right{
  margin-left: -57px;
  transform: rotate(-40deg) scale(0.7);
  transform-origin: bottom left;
  animation: flower 4s ease-in-out forwards;
  z-index: 2;
}

.flowerSvg #left{
  margin-right: -57px;
  transform: rotate(40deg) scale(0.7);
  transform-origin: bottom right;
  animation: flower 4s ease-in-out forwards;
  z-index: 2;
}


@keyframes flower {
  to{
    transform: rotate(0);
  }
  
}

.flowerSvg #center{
  opacity: 0;
  z-index: 1;
  animation: centerMove 3s ease-in-out forwards 2s;
}

@keyframes centerMove {
  to{
    transform: translateY(-150px);
    opacity: 1;
  }
}

